<template>
    <b-card title="Receive From Workshop">
        <b-form>
            <b-row>
                <b-col md="3">
                    <b-form-group label="Store Name">
                        <v-select v-model="storeName" label="storeName" :options="storeNameOptions"></v-select>
                    </b-form-group>
                </b-col>

                <b-col md="3">
                    <b-form-group label="Workshop Name">
                        <v-select v-model="workshopName" placeholder="Please Select Workshop" label="name" :options="workshopNameOptions"></v-select>
                    </b-form-group>
                </b-col>
                
                
                <b-col md="2">
                    <b-button variant="primary" @click="getReceiveFromWorkshopDetails" class="mt-2 mr-1">Filter</b-button>
                </b-col>

            </b-row>
            
            <hr>
            <b-row>
                <b-col md="5">
                    <b-button variant="secondary" disabled>{{ totalReceiveFromWorkshopList }}</b-button>
                    <div class="tableHeight">
                        <!-- table -->
                        <vue-good-table @on-selected-rows-change="selectionChangedByUnderProcess" :columns="columns"
                            :rows="rows" :rtl="direction" :search-options="{
                                enabled: true,
                                externalQuery: searchTerm
                            }" :select-options="{
    enabled: true,
    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
    selectionInfoClass: 'custom-class',
    selectionText: 'rows selected',
    clearSelectionText: 'clear',
    disableSelectInfo: true, // disable the select info panel on top
    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
}" :pagination-options="{
    enabled: false,
    perPage: pageLength
}">
                            <template slot="table-row" slot-scope="props">

                                <span v-if="props.column.field === 'orderNo'" class="text-nowrap tableText">
                                    <span class="text-nowrap tableText">{{
                                        props.row.orderNo
                                    }}</span><br>
                                    <span class="text-nowrap tableText">{{
                                        convertTimeStampToDateWithTime(props.row.dueDate)
                                    }}</span>
                                </span>

                                <span v-else-if="props.column.field === 'itemName'" class="text-nowrap tableText">
                                    <span class="text-nowrap tableText">{{
                                        props.row.itemName
                                    }}</span><br>
                                    <span class="text-nowrap tableText">{{
                                        props.row.service.name
                                    }}</span>
                                </span>

                                <span v-else-if="props.column.field === 'customerName'" class="text-nowrap tableText">
                                    <span class="text-nowrap tableText">
                                        {{ props.row.customerName }}
                                    </span><br>
                                    <span class="text-nowrap tableText">
                                        {{ props.row.customerMobileNo}}
                                    </span>
                                </span>

                                <!-- <span v-else-if="props.column.field === 'urgent'" class="text-nowrap tableText">
                                    <span class="text-nowrap">
                                        <feather-icon icon="CheckIcon" class="isUrgent" />
                                    </span>
                                </span> -->

                                <!-- Column: Common -->
                                <span class="tableText" v-else>
                                    {{ props.formattedRow[props.column.field] }}
                                </span>
                            </template>

                            <!-- pagination -->
                            <template slot="pagination-bottom" slot-scope="props">
                                <div class="d-flex justify-content-between flex-wrap">
                                    <div class="d-flex align-items-center mb-0 mt-1">
                                        <span class="text-nowrap ">
                                            Showing 1 to
                                        </span>
                                        <b-form-select v-model="pageLength" :options="['10', '20', '50', '100']"
                                            class="mx-1"
                                            @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                                        <span class="text-nowrap"> of {{ props.total }} entries </span>
                                    </div>
                                    <div>
                                        <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength"
                                            first-number last-number align="right" prev-class="prev-item"
                                            next-class="next-item" class="mt-1 mb-0"
                                            @input="(value) => props.pageChanged({ currentPage: value })">
                                            <template #prev-text>
                                                <feather-icon icon="ChevronLeftIcon" size="18" />
                                            </template>
                                            <template #next-text>
                                                <feather-icon icon="ChevronRightIcon" size="18" />
                                            </template>
                                        </b-pagination>
                                    </div>
                                </div>
                            </template>
                        </vue-good-table>
                    </div>
                </b-col>
                <b-col md="2" class="text-center">
                    <!-- <b-button block variant="danger" class="mt-1 mr-1">Return Garment</b-button> -->
                    <label class="mt-1">Move Selected</label>
                    <b-button @click="shiftRightSelected" block variant="primary" class="mt-1 mr-1">
                        <feather-icon icon="ChevronRightIcon" class="mr-50" />
                    </b-button>
                    <b-button @click="shiftLeftSelected" block variant="primary" class="mt-1 mr-1">
                        <feather-icon icon="ChevronLeftIcon" class="mr-50" />
                    </b-button>
                    <label class="mt-1">Move All</label>
                    <b-button @click="moveAllRight" block variant="primary" class="mt-1 mr-1">
                        <feather-icon icon="ChevronsRightIcon" class="mr-50" />
                    </b-button>
                    <b-button @click="moveAllLeft" block variant="primary" class="mt-1 mr-1">
                        <feather-icon icon="ChevronsLeftIcon" class="mr-50" />
                    </b-button>
                </b-col>

                <b-col md="5" class="text-right">
                    <b-row>
                        <b-col md="10">
                            <b-button variant="primary" @click="receiveFromWorkShop">Receive From Workshop</b-button>
                        </b-col>
                        <b-col md="2">
                            <b-button variant="secondary" disabled>{{ totalSelectedOrderItem }}</b-button>
                        </b-col>
                    </b-row>
                    <div class="tableHeight">
                        <!-- table -->
                        <vue-good-table @on-selected-rows-change="selectionChangedBysendToWorkshop" :columns="columns"
                            :rows="rowsSelected" :rtl="direction" :search-options="{
                                enabled: true,
                                externalQuery: searchTerm
                            }" :select-options="{
    enabled: true,
    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
    selectionInfoClass: 'custom-class',
    selectionText: 'rows selected',
    clearSelectionText: 'clear',
    disableSelectInfo: true, // disable the select info panel on top
    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
}" :pagination-options="{
    enabled: false,
    perPage: pageLength
}">
                            <template slot="table-row" slot-scope="props">

                                <span v-if="props.column.field === 'orderNo'" class="text-nowrap tableText">
                                    <span class="text-nowrap tableText">{{
                                        props.row.orderNo
                                    }}</span><br>
                                    <span class="text-nowrap tableText">{{
                                        convertTimeStampToDateWithTime(props.row.dueDate)
                                    }}</span>
                                </span>

                                <span v-else-if="props.column.field === 'itemName'" class="text-nowrap tableText">
                                    <span class="text-nowrap tableText">{{
                                        props.row.itemName
                                    }}</span><br>
                                    <span class="text-nowrap tableText">{{
                                        props.row.service.name
                                    }}</span>
                                </span>

                                <!-- <span v-else-if="props.column.field === 'urgent'" class="text-nowrap tableText">
                                    <span class="text-nowrap">
                                        <feather-icon icon="CheckIcon" class="isUrgent" />
                                    </span>
                                </span> -->

                                <!-- Column: Common -->
                                <span class="tableText" v-else>
                                    {{ props.formattedRow[props.column.field] }}
                                </span>
                            </template>

                            <!-- pagination -->
                            <template slot="pagination-bottom" slot-scope="props">
                                <div class="d-flex justify-content-between flex-wrap">
                                    <div class="d-flex align-items-center mb-0 mt-1">
                                        <span class="text-nowrap ">
                                            Showing 1 to
                                        </span>
                                        <b-form-select v-model="pageLength" :options="['3', '5', '10']" class="mx-1"
                                            @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                                        <span class="text-nowrap"> of {{ props.total }} entries </span>
                                    </div>
                                    <div>
                                        <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength"
                                            first-number last-number align="right" prev-class="prev-item"
                                            next-class="next-item" class="mt-1 mb-0"
                                            @input="(value) => props.pageChanged({ currentPage: value })">
                                            <template #prev-text>
                                                <feather-icon icon="ChevronLeftIcon" size="18" />
                                            </template>
                                            <template #next-text>
                                                <feather-icon icon="ChevronRightIcon" size="18" />
                                            </template>
                                        </b-pagination>
                                    </div>
                                </div>
                            </template>
                        </vue-good-table>
                    </div>
                </b-col>
            </b-row>

        </b-form>
    </b-card>
</template>
  
<script>
import {
    BAvatar, BCard, BRow, BCol, BForm, BButton, BBadge, BPagination,
    BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BFormCheckbox
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import api from '@/store/api'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    components: {
        VueGoodTable, BFormCheckbox,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem, BCard, BRow, BCol, BForm, BButton,
        vSelect, flatPickr

    },
    data() {
        return {
            storeNameOptions: [],
            storeName: { 'id': this.$store.state.storeId, 'storeName': this.$store.state.storeName },
            orderId: 0,
            garmentProcessStatusOption: [],
            garmentProcessStatus: '',
            fromDate: null,
            toDate: null,
            customersOrderNo: [],
            searchBoxForOrderNo: '',
            pageLength: 10,
            dir: false,
            columns: [
                {
                    label: 'Order No / Due Date',
                    field: 'orderNo',
                    width: '100px'
                },
                // {
                //     label: 'Due Date',
                //     field: 'dueDate',
                //     width: '100px'
                // },
                {
                    label: 'Barcode',
                    field: 'qrCode',
                    width: '100px'
                },
                {
                    label: 'Customer',
                    field: 'customerName',
                    width: '100px'
                },
                {
                    label: 'Garment',
                    field: 'itemName',
                    width: '100px'
                },
                // {
                //     label: 'U',
                //     field: 'urgent',
                //     width: '100px'
                // },
                // {
                //     label: 'Service',
                //     field: 'service.name',
                //     width: '100px'
                // },
            ],
            rows: [],
            rowsSelected: [],
            searchTerm: '',
            totalReceiveFromWorkshopList: 0,
            totalSelectedOrderItem: 0,
            orderItemList: [],
            selectedOrderItemList: [],
            workshopName: '',
            workshopNameOptions: []
        }
    },
    methods: {
        convertTimeStampToDateWithTime(value) {
            if (value == null) {
                return '';
            } else {
                return moment.utc(value).format('DD-MM-YYYY');
            }
        },
        getStoreName() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/stores', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.storeNameOptions = response.data;


                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getWorkshopDetails() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/workshops', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.workshopNameOptions = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getReceiveFromWorkshopDetails() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/workshop/getWorkshopItemPiceList/'+self.storeName.id+','+self.workshopName.id, data))
                .then(function (response) {
                    if (response.data != null) {
                        self.rows = response.data;
                        self.totalReceiveFromWorkshopList = response.data.length

                        self.rowsSelected = [];
                        self.totalSelectedOrderItem = 0;
                    } else {
                        self.rows = [];
                    }

                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        selectionChangedByUnderProcess(params) {
            this.orderItemList = params.selectedRows;

        },
        selectionChangedBysendToWorkshop(params) {
            this.selectedOrderItemList = params.selectedRows;

        },
        shiftRightSelected() {
            for (let index = 0; index < this.orderItemList.length; index++) {
                this.rowsSelected.push(this.orderItemList[index]);
                this.totalSelectedOrderItem = this.rowsSelected.length;

                const objectIndex = this.rows.findIndex(obj => obj.id == this.orderItemList[index].id)
                this.rows.splice(objectIndex, 1);
                this.totalReceiveFromWorkshopList = this.rows.length;
            }
        },
        shiftLeftSelected() {
            for (let index = 0; index < this.selectedOrderItemList.length; index++) {
                this.rows.push(this.selectedOrderItemList[index]);
                this.totalReceiveFromWorkshopList = this.rows.length;

                const objectIndex = this.rowsSelected.findIndex(obj => obj.id == this.selectedOrderItemList[index].id)
                this.rowsSelected.splice(objectIndex, 1);
                this.totalSelectedOrderItem = this.rowsSelected.length;
            }
        },
        moveAllRight() {
            this.rowsSelected = this.rows;
            this.totalSelectedOrderItem = this.rowsSelected.length;

            this.rows = []
            this.totalReceiveFromWorkshopList = this.rows.length;
        },

        moveAllLeft() {
            this.rows = this.rowsSelected;
            this.totalReceiveFromWorkshopList = this.rows.length;

            this.rowsSelected = [];
            this.totalSelectedOrderItem = this.rowsSelected.length;
        },
        receiveFromWorkShop() {
            if (this.rowsSelected.length > 0) {
                this.$swal({
                    // title: 'Are you sure?',
                    text: "Are You Sure You Want To Receive From Workshop!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Ok',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,
                }).then(result => {
                    if (result.value) {
                        let self = this;
                        var axios = require('axios');
                        var data = [];
                        for (let index = 0; index < this.rowsSelected.length; index++) {
                            data.push(this.rowsSelected[index].id);
                        }
                        
                        axios(api.getApi('post', '/workshop/recivedWorkshopItemAtStore/'+self.storeName.id+','+self.$store.state.empId, data))
                            .then(function (response) {
                                //console.log(JSON.stringify(response.data));
                                self.rowsSelected=[];
                                self.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `Recevived From Workshop Successfully Done!`,
                                    icon: 'SaveIcon',
                                    variant: 'success',
                                },
                            })
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    }
                })
            } else {
                this.$swal({
                    // title: 'Are you sure?',
                    text: "Please Select order Item List",
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                });
            }
        }
    },
    computed: {
    },
    created() {
        this.getStoreName();

        this.getWorkshopDetails();
    },
}
</script>
<style lang="css">
@import '~vue-good-table/dist/vue-good-table.css';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style>
.tableText {
    font-size: 11px;
}

.tableHeight {
    overflow-y: scroll;
    height: 500px;
}

.isUrgent {
    color: green;
    width: 26px;
    height: 26px;
}
</style>